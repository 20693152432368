<template>
  <section id="infrastructure" class="pt-infrastructure pb-140">
    <div class="container">
      <div class="row">
        <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-6">
          <h2 class="sec-title">Infrastruktur.</h2>
        </div>
        <div class="col-xxl-7 col-xl-7 col-lg-6 col-md-6">
          <div class="sec-text">
            <p>
              Merupakan detail data rekap jumlah aset dan infrastruktur yang
              terdata didalam Sistem Informasi Air Tanah dan Baku (SIATAB) yang
              mana mencakup seluruh Balai Wilayah Sungai / Balai Besar Wilayah
              Sungai
            </p>
          </div>
        </div>
      </div>
      <div class="portfolio__service-list">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
            <div class="portfolio__service-item">
              <a href="#">
                <div class="d-flex">
                  <div class="ps-title me-3 align-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-sailboat"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1"
                      />
                      <path d="M4 18l-1 -3h18l-1 3" />
                      <path d="M11 12h7l-7 -9v9" />
                      <path d="M8 7l-2 5" />
                    </svg>
                  </div>
                  <h3 class="ps-title">
                    Intake Sungai <br />
                    <span class="fw-bold">{{ totalIntakeSungai }}</span>
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
            <div class="portfolio__service-item">
              <a href="#">
                <div class="d-flex">
                  <div class="ps-title me-3 align-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-cylinder"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 6m-7 0a7 3 0 1 0 14 0a7 3 0 1 0 -14 0" />
                      <path d="M5 6v12c0 1.657 3.134 3 7 3s7 -1.343 7 -3v-12" />
                    </svg>
                  </div>
                  <h3 class="ps-title">
                    Tampungan <br />
                    <span class="fw-bold">{{ totalTampungan }}</span>
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
            <div class="portfolio__service-item">
              <a href="#">
                <div class="d-flex">
                  <div class="ps-title me-3 align-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-box"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 3l8 4.5l0 9l-8 4.5l-8 -4.5l0 -9l8 -4.5" />
                      <path d="M12 12l8 -4.5" />
                      <path d="M12 12l0 9" />
                      <path d="M12 12l-8 -4.5" />
                    </svg>
                  </div>
                  <h3 class="ps-title">
                    PAH / ABSAH <br />
                    <span class="fw-bold">{{ totalPahAbsah }}</span>
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
            <div class="portfolio__service-item">
              <a href="#">
                <div class="d-flex">
                  <div class="ps-title me-3 align-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="icon icon-tabler icons-tabler-filled icon-tabler-droplet-half-2"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M13.905 2.923l.098 .135l4.92 7.306a7.566 7.566 0 0 1 1.043 3.167l.024 .326c.007 .047 .01 .094 .01 .143l-.002 .06c.056 2.3 -.944 4.582 -2.87 6.14c-2.969 2.402 -7.286 2.402 -10.255 0c-1.904 -1.54 -2.904 -3.787 -2.865 -6.071a1.052 1.052 0 0 1 .013 -.333a7.66 7.66 0 0 1 .913 -3.176l.172 -.302l4.893 -7.26c.185 -.275 .426 -.509 .709 -.686c1.055 -.66 2.446 -.413 3.197 .55zm-2.06 1.107l-.077 .038l-.041 .03l-.037 .036l-.033 .042l-4.863 7.214a5.607 5.607 0 0 0 -.651 1.61h11.723a5.444 5.444 0 0 0 -.49 -1.313l-.141 -.251l-4.891 -7.261a.428 .428 0 0 0 -.5 -.145z"
                      />
                    </svg>
                  </div>
                  <h3 class="ps-title">
                    MATA AIR <br />
                    <span class="fw-bold">{{ totalMataAir }}</span>
                  </h3>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 mb-3">
            <div class="portfolio__service-item">
              <a href="#">
                <div class="d-flex">
                  <div class="ps-title me-3 align-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-hexagonal-prism"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20.792 6.996l-3.775 2.643a2.005 2.005 0 0 1 -1.147 .361h-7.74c-.41 0 -.81 -.126 -1.146 -.362l-3.774 -2.641"
                      />
                      <path d="M8 10v11" />
                      <path d="M16 10v11" />
                      <path
                        d="M3.853 18.274l3.367 2.363a2 2 0 0 0 1.147 .363h7.265c.41 0 .811 -.126 1.147 -.363l3.367 -2.363c.536 -.375 .854 -.99 .854 -1.643v-9.262c0 -.655 -.318 -1.268 -.853 -1.643l-3.367 -2.363a2 2 0 0 0 -1.147 -.363h-7.266c-.41 0 -.811 .126 -1.147 .363l-3.367 2.363a2.006 2.006 0 0 0 -.853 1.644v9.261c0 .655 .318 1.269 .853 1.644z"
                      />
                    </svg>
                  </div>
                  <h3 class="ps-title">
                    Sumur <br />
                    <span class="fw-bold">{{ totalSumur }}</span>
                  </h3>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axiosIns from "@/libs/axios";

export default {
  name: "Infrastructure",
  data() {
    return {
      totalIntakeSungai: 0,
      totalPahAbsah: 0,
      totalTampungan: 0,
      totalMataAir: 0,
      totalSumur: 0,
      loading: true,
      error: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await axiosIns.get(
          "/v2/public/landing/infrastructure/total"
        );
        const data = response.data.data;
        this.totalIntakeSungai = data.intake_sungai;
        this.totalPahAbsah = data.pah_absah;
        this.totalTampungan = data.tampungan;
        this.totalMataAir = data.mata_air;
        this.totalSumur = data.sumur;
      } catch (error) {
        this.error = "Failed to load data: " + error.message;
        alert("error" + error.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.pt-infrastructure {
  padding-top: 140px;
}

@media (min-width: 576px) {
  .pt-infrastructure {
    padding-top: 200px;
  }
}
</style>
